<template>
  <layout
    style="background: #f2f2f2;"
    :title="$route.name"
    :options="options"
    :rotas="rotas"
  >
    <div class="expande-horizontal wrap">
      <v-flex xs12 lg3>
        <Filtros class="pl-3" />
        <v-flex
          class="pa-3"
          v-if="getPersonalizedLoading === 'listando-posts'"
          xs12
        >
          <v-skeleton-loader
            type="list-item-avatar, divider"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="list-item-avatar, divider"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="list-item-avatar, divider"
          ></v-skeleton-loader>
        </v-flex>
        <v-flex class="pa-3" v-else xs12>
          <v-list
            class="pa-0 ma-0"
            dense
            style="min-height: 20vh; overflow: auto;"
          >
            <template v-for="(post, index) in get_posts.docs">
              <v-list-item
                :class="{
                  'item-selected': get_post._id === post._id ? true : false
                }"
                @click="abrirpost(post, index)"
                style="border-radius: 6px; background: #f2f2f2; margin-bottom: 6px;"
                :key="post._id"
              >
                <v-list-item-content>
                  <v-list-item-title class="fonte-subtitulo font-weight-bold">
                    {{ post.titulo }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    :class="{
                      'white--text': get_post._id === post._id ? true : false
                    }"
                    class="fonte-mini fonte-subtitulo"
                  >
                    {{ post.descricao }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    :class="{
                      'white--text': get_post._id === post._id ? true : false
                    }"
                    class="fonte-mini fonte-subtitulo"
                  >
                    Criado
                    {{ $moment(get_post.created_at).format("DD/MM/YYYY") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    class="animate__animated animate__fadeIn"
                    v-if="get_post._id === post._id"
                    icon
                  >
                    <v-icon color="white"> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-flex>
        <v-flex v-if="get_posts.docs.length === 0" xs12>
          <div
            class="expande-horizontal pa-6 column centraliza"
            style="height: 36vh;"
          >
            <img style="width: 80%" src="img/empty.gif" alt="empty" />
            <span
              class="px-3 fonte-italica fonte-subtitulo grey--text text-center"
            >
              Nenhum post encontrado, clique no botão abaixo e cadastre um!
            </span>
            <v-btn
              small
              rounded
              dark
              class=" mt-6 fonte-subtitulo fonte-italica"
              :color="$theme.secondary"
              @click="iniciarCadastro"
            >
              Novo post
              <v-icon
                size="12"
                class="ml-1 animate__animated"
                :class="getPersonalizedLoading"
                >mdi-arrow-projectile</v-icon
              >
            </v-btn>
          </div>
        </v-flex>
        <v-flex v-if="get_posts.docs.length" class="pa-3" xs12>
          <Paginacao />
        </v-flex>
      </v-flex>
      <!-- <v-flex xs0 md0 lg1></v-flex> -->
      <v-flex v-if="$vuetify.breakpoint.lgAndUp" xs12 md9 lg9>
        <div
          v-if="get_post.new || get_post._id"
          class="expande-horizontal centraliza wrap"
        >
          <!-- <div class="expande-horizontal"> -->
          <span class="fonte fonteMini grey--text"> Opções </span>
          <!-- </div> -->
          <v-flex xs12>
            <div class="fonte expande-horizontal centraliza">
              <v-select
                v-model="get_post.destiny"
                dense
                label="Destino"
                hide-details
                :items="['ale', 'cosmos']"
              ></v-select>
              <v-switch
                label="Mostrar post"
                dense
                outlined
                color="green"
                class="fonteMini fonte font-weight-bold"
                v-model="get_post.disponivel"
              ></v-switch>
              <v-btn
                class="ml-3 fonte font-weight-bold fonteMini"
                dark
                rounded
                :disabled="disableBtn || get_post.sended"
                color="green"
                @click="sendNewsLetter"
                small
                >News Letter <v-icon size="19" class="ml-3">mdi-check</v-icon>
              </v-btn>
              <v-icon v-if="get_post.sended" color="green" class="ml-2"
                >mdi-check-all</v-icon
              >
            </div>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              label="Slug"
              dense
              outlined
              @blur="createOrUpdate"
              v-model="get_post.slug"
            ></v-text-field>
            <v-text-field
              label="Titulo"
              dense
              outlined
              @blur="createOrUpdate"
              v-model="get_post.titulo"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-textarea
              outlined
              label="Descrição"
              @blur="createOrUpdate"
              v-model="get_post.descricao"
            ></v-textarea>
          </v-flex>
          <v-flex xs12>
            <ModalSendArchive />
          </v-flex>
          <v-flex :class="{ 'pr-3': $vuetify.breakpoint.lgAndUp }" xs12 md4>
            <v-text-field
              outlined
              dense
              @blur="createOrUpdate"
              label="Categoria"
              v-model="get_post.categoria"
              hint="Escreva a categoria"
            ></v-text-field>
          </v-flex>
          <v-flex :class="{ 'pr-3': $vuetify.breakpoint.lgAndUp }" xs12 md4>
            <v-text-field
              outlined
              label="Tags"
              dense
              @blur="createOrUpdate"
              v-model="get_post.tags"
              hint="Separe as tags por espaço"
            ></v-text-field>
          </v-flex>
          <v-flex :class="{ 'pr-3': $vuetify.breakpoint.lgAndUp }" xs12 md4>
            <v-text-field
              outlined
              label="Tempo de leitura"
              @blur="createOrUpdate"
              dense
              v-model="get_post.tempo_de_leitura"
              hint="ex: 5 minutos"
            ></v-text-field>
          </v-flex>
          <froala
            :tag="'textarea'"
            :config="config"
            v-model="get_post.post_text"
          />
          <v-flex xs12>
            <div class="expande-horizontal">
              <v-btn
                dark
                class="elevation-0 mt-3"
                color="green"
                @click="createOrUpdate"
                >Salvar</v-btn
              >
            </div>
          </v-flex>
        </div>
      </v-flex>
      <v-dialog
        v-else
        transition="slide-x-transition"
        fullscreen
        :value="get_post._id ? true : false"
      >
        <v-card class="pa-4">
          <div class="expande-horizontal centraliza wrap">
            <v-flex xs12>
              <div class="windows-style expande-horizontal fonte column">
                <v-list-item class="pa-0 ma-0 px-2">
                  <v-avatar
                    @click="backToList"
                    style="cursor: pointer;"
                    size="27"
                    :color="$theme.primary"
                    class="mr-2"
                    icon
                  >
                    <v-icon color="#f2f2f2">mdi-close</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">
                      {{ get_post.new ? "Novo post" : get_post.titulo }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="font-mini">
                      Gerencie os detalhes abaixo
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-flex>
            <v-flex xs12 md9 lg9 class="pa-3">
              <div
                v-if="get_post._id"
                class="expande-horizontal centraliza wrap"
              >
                <!-- <div class="expande-horizontal"> -->
                <span class="fonte fonteMini grey--text"> Opções </span>
                <!-- </div> -->
                <v-flex xs12>
                  <div class="fonte expande-horizontal centraliza">
                    <v-select
                      v-model="get_post.destiny"
                      dense
                      label="Destino"
                      hide-details
                      :items="['ale', 'cosmos']"
                    ></v-select>
                    <v-switch
                      label="Mostrar post"
                      dense
                      outlined
                      color="green"
                      class="fonteMini fonte font-weight-bold"
                      v-model="get_post.disponivel"
                    ></v-switch>
                    <v-btn
                      class="ml-3 fonte font-weight-bold fonteMini"
                      dark
                      rounded
                      :disabled="disableBtn || get_post.sended"
                      color="green"
                      @click="sendNewsLetter"
                      small
                      >News Letter
                      <v-icon size="19" class="ml-3">mdi-check</v-icon>
                    </v-btn>
                    <v-icon v-if="get_post.sended" color="green" class="ml-2"
                      >mdi-check-all</v-icon
                    >
                  </div>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="Slug"
                    dense
                    outlined
                    @blur="createOrUpdate"
                    v-model="get_post.slug"
                  ></v-text-field>
                  <v-text-field
                    label="Titulo"
                    dense
                    outlined
                    @blur="createOrUpdate"
                    v-model="get_post.titulo"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    outlined
                    label="Descrição"
                    @blur="createOrUpdate"
                    v-model="get_post.descricao"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12>
                  <ModalSendArchive />
                </v-flex>
                <v-flex
                  :class="{ 'pr-3': $vuetify.breakpoint.lgAndUp }"
                  xs12
                  md4
                >
                  <v-text-field
                    outlined
                    dense
                    @blur="createOrUpdate"
                    label="Categoria"
                    v-model="get_post.categoria"
                    hint="Escreva a categoria"
                  ></v-text-field>
                </v-flex>
                <v-flex
                  :class="{ 'pr-3': $vuetify.breakpoint.lgAndUp }"
                  xs12
                  md4
                >
                  <v-text-field
                    outlined
                    label="Tags"
                    dense
                    @blur="createOrUpdate"
                    v-model="get_post.tags"
                    hint="Separe as tags por espaço"
                  ></v-text-field>
                </v-flex>
                <v-flex
                  :class="{ 'pr-3': $vuetify.breakpoint.lgAndUp }"
                  xs12
                  md4
                >
                  <v-text-field
                    outlined
                    label="Tempo de leitura"
                    @blur="createOrUpdate"
                    dense
                    v-model="get_post.tempo_de_leitura"
                    hint="ex: 5 minutos"
                  ></v-text-field>
                </v-flex>
                <froala
                  :tag="'textarea'"
                  :config="config"
                  v-model="get_post.post_text"
                />
                <v-flex xs12>
                  <div class="expande-horizontal">
                    <v-btn
                      dark
                      class="elevation-0 mt-3"
                      color="green"
                      @click="createOrUpdate"
                      >Salvar</v-btn
                    >
                  </div>
                </v-flex>
              </div>
            </v-flex>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </layout>
</template>

<script>
import Filtros from "../components/Filtros.vue";
import VisualizacaoEmCard from "../components/VisualizacaoEmCard.vue";
import VisualizacaoEmLista from "../components/VisualizacaoEmLista.vue";
import Paginacao from "../components/Paginacao.vue";
import ListagemVazia from "../components/ListagemVazia.vue";
import ModalView from "../components/modalView.vue";
import { mapActions, mapGetters } from "vuex";
import ModalSendArchive from "../../shared/components/ModalSendArchive.vue";

import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/languages/pt_br.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/themes/dark.css";
import VueFroala from "vue-froala-wysiwyg";

export default {
  data() {
    return {
      card: false,
      contextDialog: false,
      drawer: false,
      content: "",
      config: {
        events: {
          "froalaEditor.initialized": function() {
            console.log("initialized");
          }
        }
      },
      options: [
        {
          nome: "Novo post",
          action: this.iniciarCadastro,
          icon: "mdi-plus"
        }
      ],
      rotas: [
        {
          name: "Início",
          path: ""
        },
        {
          name: "Meus Posts",
          path: "/posts"
        }
      ],
      get_categoria: {
        posts: []
      },
      disableBtn: false
    };
  },
  components: {
    Filtros,
    VisualizacaoEmCard,
    VisualizacaoEmLista,
    Paginacao,
    ListagemVazia,
    ModalView,
    ModalSendArchive,
    VueFroala
  },
  computed: {
    ...mapGetters([
      "get_tenants",
      "get_estoque",
      "get_estoques",
      "get_tenants_filtros",
      "get_posts_filtros",
      "get_estoques_filtros",
      "get_postcategorias",
      "get_postcategoria",
      "getLoggedUser",
      "get_post",
      "get_posts",
      "getLinksById",
      "getPersonalizedLoading"
    ])
  },
  methods: {
    backToList() {
      this.$store.commit("set_post", {});
      this.$forceUpdate();
    },
    sendNewsLetter() {
      this.disableBtn = true;
      this.$run("posts/send-post", {
        id_post: this.get_post._id
      }).then(res => {
        this.disableBtn = false;
        this.$store.commit("set_post", res.data);
      });
    },
    excluir_post_dialog(item) {
      let text = "Deseja remover este post?";
      this.createConfirmAction({
        message: text,
        icon: "mdi-close-circle",
        action: "excluir_post",
        action_value: item
      });
    },
    excluir_estoque_dialog(item) {
      let text = "Deseja remover registro do estoque?";
      this.createConfirmAction({
        message: text,
        icon: "mdi-close-circle",
        action: "excluir_estoque",
        action_value: item
      });
    },
    backToList() {
      this.$store.commit("set_post", {});
      this.$forceUpdate();
    },
    sumValueBuyed(val) {
      let qtd = val.quantidade || 0;
      let priceBuy = val.preco_de_compra || 0;
      let sum = qtd * priceBuy;
      return this.$helper.formataSaldo(sum);
    },
    forceUpdate() {
      this.$forceUpdate();
    },
    abrirpost(post) {
      this.$store.commit("set_post", post);
      this.$store.commit("setLinks", post.images);
    },
    removePrecoMultiplo(index) {
      let novoPrecoMultiplo = this.get_post.preco_multiplo.filter(
        (p, i) => i !== index
      );
      this.get_post.preco_multiplo = novoPrecoMultiplo;
    },
    getLinkById(id) {
      return this.getLinkById[id];
    },
    closeContextDialog() {
      this.contextDialog = false;
      setTimeout(() => {
        this.drawer = true;
      }, 10);
    },
    poc(text) {
      console.log(text);
    },
    ...mapActions([
      "abre_modal_view_tenant",
      "listar_tenants",
      "listar_posts",
      "criar_estoque",
      "listar_postcategorias",
      "criar_post",
      "listar_estoques",
      "atualizar_post",
      "setPersonalizedLoading",
      "createConfirmAction"
    ]),
    adicionarNovoPreco() {
      if (this.$refs.formNewPrecoMultiplo.validate()) {
        const preco = this.formPrecoMultiplo;
        this.get_post.preco_multiplo.push(preco);
        this.formPrecoMultiplo = {};
      }
    },
    criarOuAtualizar() {
      if (this.get_post._id) {
        this.atualizar_post();
      } else {
        this.criar_post();
      }
    },
    adicionarEstoque() {
      if (this.$refs.formEstoque.validate()) {
        this.get_estoque.produto = this.get_post;
        this.get_estoque.origem = "post";
        this.criar_estoque();
        this.formEstoqueDialog = false;
      }
    },
    imprimir() {
      this.card = false;
      this.$refs.html2Pdf.generatePdf();
    },
    iniciarCadastro() {
      this.setPersonalizedLoading("animate__fadeOutTopRight");
      this.$store.commit("set_post", {
        new: true,
        preco_multiplo: []
      });
      this.$store.commit("setLinks", []);
      this.setPersonalizedLoading("animate__fadeInUp");
      this.$store.commit("set_estoques", {
        docs: [],
        page: 1,
        totalPages: 1
      });
    },
    createOrUpdate() {
      if (this.get_post._id) {
        this.atualizar_post();
      } else {
        this.criar_post();
      }
    }
  },
  created() {
    this.get_posts_filtros.limitPerPage = 7;
    this.listar_posts();
  }
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.item-list {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #ab47bc;
}
.item-selected {
  background: #ab47bc !important;
  color: #fff !important;
}
</style>
