<template>
  <v-pagination
    style="background: #fff;"
    :color="$theme.secondary"
    total-visible="7"
    circle
    class="fonte-subtitulo fonte-italica"
    :elevation="0"
    v-model="get_posts_filtros.page"
    :length="get_posts.totalPages"
    @input="setPage($event)"
  ></v-pagination>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_posts", "get_posts_filtros"])
  },
  methods: {
    ...mapActions(["listar_posts"]),
    setPage(page) {
      if (page) {
        console.log("page", page);
        this.get_posts_filtros.page = page;
        this.listar_posts();
      }
    }
  }
};
</script>
